<template>
  <section>
    <HeroSection headName="Cadastro de Fatura" :searchBar="false" :addButton="false">
    </HeroSection>

    <div class="container">

      <form class="form">
        <div class="form__box" v-if="loading">
          <h2 class="form__name">
            <DataLoading rows="1" height="50px" />
          </h2>

          <div class="form__content">
            <DataLoading rows="4" height="80px" />
          </div>
        </div>

        <div class="form__wrap" v-if="!loading">
          <div class="form__box">
            <h2 class="form__name">
              Dados do Cliente
            </h2>

            <div class="form__content">
              <div class="form__row">
                <FormSearch label="Pesquisar Cliente" v-model:modelValue="searcher" v-model:modelItem="customer" :dataList="customersList" />
              </div>

              <div class="form__row">
                <FormInput label="CPF" :required="true" v-model:modelValue="helpers.cpf" mask="000.000.000-00" v-if="isCpf" idx="cpf" width="24%"/>

                <FormInput label="CNPJ" :required="true" v-model:modelValue="helpers.cnpj" mask="00.000.000/0000-00" v-if="!isCpf"  idx="cnpj"  width="24%"/>

                <FormInput label="Nome Fantasia" :required="true" v-model:modelValue="model.customer.name" width="26%"/>

                <FormInput label="Razão Social" :required="true" v-model:modelValue="model.customer.company" width="26%"/>
              </div>
            </div>
          </div>

          <div class="form__box">
            <h2 class="form__name">
              Dados de Fatura
            </h2>

            <div class="form__content">
              <div class="form__row">
                <FormInput label="Valor da Fatura" :required="true" v-model:modelValue="model.invoice_value" money width="25%"/>

                <FormInput label="Vencimento" :required="true" v-model:modelValue="model.maturity_date" typex="date" width="25%"/>

                <FormSelect label="Tipo de Serviço" :required="true" :dataList="typeServices()" v-model:modelValue="model.service_type" width="50%" />
              </div>

              <div class="form__row">
                <FormInput label="Descritivo Nfse" :required="false" v-model:modelValue="model.short_desc"  width="100%"/>
              </div>
            </div>
          </div>

          <div class="form__box">
            <h2 class="form__name">
              Descritivo
            </h2>

            <div class="form__content">
              <div class="form__row">
                <FormEditor label="Descritivo de Fatura" :required="true" v-model:modelValue="model.full_desc"/>
              </div>
            </div>
          </div>

          <div class="form__box">
            <h2 class="form__name">
              Pré Emissão
            </h2>

            <div class="form__content">
              <div class="form__row form__row--nowrap">
                <FormCheckbox label="Enviar por E-mail" :required="true" v-model:modelValue="model.send_mail" />

                <FormCheckbox label="Emitir Nota Fiscal" :required="true" v-model:modelValue="model.has_nfse"/>

                <FormCheckbox style="opacity: 0.4; cursor: not-allowed" label="Inserir em Agendamentos" :required="false" v-model:modelValue="model.schedule_temp"/>

                <FormCheckbox label="Valor Faturado" :required="true" v-model:modelValue="model.added_value" v-show="roleRule(['admin'])"/>
              </div>
            </div>
          </div>

          <div class="form__box" v-if="model._cod">
            <h2 class="form__name">
              Pós Emissão
            </h2>

            <div class="form__content">
              <div class="form__row">
                <FormSelect label="Ações" :required="false" :dataList="actionsList" v-model:modelValue="action" width="100%" />
              </div>
            </div>
          </div>

          <FormBottom :data="button" @submit="submit()" />

        </div>
      </form>
    </div>
  </section>
</template>

<script>

import HeroSection from '@components/_partials/HeroSection.vue';
import DataLoading from '@components/_partials/DataLoading.vue';
import FormSearch from '@components/_form/FormSearch.vue';
import FormInput from '@components/_form/FormInput.vue';
import FormSelect from '@components/_form/FormSelect.vue';
import FormCheckbox from '@components/_form/FormCheckbox.vue';
import FormEditor from '@components/_form/FormEditor.vue';
import FormBottom from '@components/_form/FormBottom.vue';
import { baseUrl, padVal, brDate, docMask, phoneMask, brMoney, globalDate } from '@helpers/functions';
import { typeServices, schedulePeriod, isActive } from '@helpers/constants'
import fatura from '@services/fatura';
import cliente from '@services/cliente';
import storage from '@/config/storage';

export default {
  components: {
    HeroSection,
    DataLoading,
    FormSearch,
    FormInput,
    FormSelect,
    FormEditor,
    FormCheckbox,
    FormBottom
  },
  data() {
    return {
      loading: false,
      button: { spinner: false, label: 'Salvar Fatura', color: 'primary' },
      model: { customer: {}, days_to_send: 5, is_active: false, has_nfse: false, added_value: true },
      actionsList: [
        //{ label: 'Reenviar E-mail', value: 'reenviar_email' },
        //{ label: 'Gerar Nota Fiscal', value: 'gerar_nfse' },
        { label: 'Arquivar Fatura (pago manualmente)', value: 'arquivar_fatura' },
        { label: 'Cancelar Fatura', value: 'cencelar_fatura' }
      ],
      action: {},
      customersList: [],
      customer: {},
      dataList: [],
      pagination: {},
      searcher: '',
      search: '',
      isCpf: true,
      helpers: {
        cpf: '',
        cnpj: ''
      }
    }
  },
  methods: {
    baseUrl,
    brDate,
    padVal,
    docMask,
    phoneMask,
    brMoney,
    globalDate,
    typeServices,
    schedulePeriod,
    isActive,
    loadForm: function(_id) {
      const _this = this;
      _this.loading = true;

      fatura.show(_id).then((res) => {
        if(typeof res !== 'undefined' && res.success) {
          _this.model = res.data;
          _this.loading = false;

          _this.model.invoice_value = brMoney(_this.model.invoice_value, 'decimal');
          _this.model.maturity_date = globalDate(_this.model.maturity_date);

          _this.helpers.cpf = _this.model.customer.document;
          _this.isCpf = true;
          if(_this.model.customer.document.length == 14) {
            _this.helpers.cnpj = _this.model.customer.document;
            _this.isCpf = false;
          }
        }
      });
    },
    submit() {
      const _this = this;
      const _id = _this.hasIdx();
      const button = _this.button;

      let data = _this.model;
      let service = 'save';

      if(_this.model.archive === true) {
        service = 'archive';
      }

      if(_this.model.cancel === true) {
        service = 'cancel';
      }

      _this.button = {
        spinner: true,
        label: 'Salvando...',
        color: 'warning'
      }
      fatura[service](_id, data).then((res) => {
        if(typeof res !== 'undefined' && res.success) {
          _this.button = {
            spinner: false,
            label: 'Salvo com sucesso!',
            color: 'success'
          }

          setTimeout(() => this.$router.push('/faturas'), 1000);
        }

        if(typeof res === 'undefined' || !res.success) {
          _this.button = {
            spinner: false,
            label: 'Houve um erro ao processar.',
            color: 'danger'
          }
          setTimeout(() => _this.button = button, 3000);
        }
      })

    },
    hasIdx() {
      const _this = this;
      return _this.$route.params.id ? _this.$route.params.id : null;
    },
    roleRule(roles) {
      const userRole = storage.get('flizecore--user', 'role');
      const filtered = roles.filter(item => item == userRole)
      return filtered.length > 0 ? true : false;
    }
  },
  watch: {
    '$route.params.page'(paged) {
     this.initLoad(paged);
    },
    'helpers.cpf'(doc) {
      const _this = this;
      if(doc && doc.length >= 15) {
        _this.isCpf = false;
        _this.helpers.cnpj = doc;
        _this.model.document = doc;
        setTimeout(() => {
          document.getElementById('cnpj').focus();
        }, 200);
      }
    },
    'helpers.cnpj'(doc) {
      const _this = this;
      if(doc && doc.length <= 11) {
        _this.isCpf = true;
        _this.helpers.cpf = doc;
        _this.model.document = doc;
        setTimeout(() => {
          document.getElementById('cpf').focus();
        }, 200);
      }
    },
    'searcher'(search) {
      const _this = this;
      if(search && search.length > 3) {
        cliente.retrieve({ search }).then((res) => {
        if(typeof res !== 'undefined' && res.success) {
          const { docs } = res.data;
          _this.customersList = docs;
        }
      });
      }

      if(!search || search.length == 0) {
        _this.customersList = [];
      }
    },
    'customer'(customer) {
      const _this = this;
      const { _id, name, company, document } = customer;

      _this.helpers.cpf = document;
      _this.isCpf = true;
      if(document.length == 14) {
        _this.helpers.cnpj = document;
        _this.isCpf = false;
      }

      _this.model.customer = { _id, name, company };
      _this.customersList = [];
      _this.searcher = '';
    },
    'action'(action) {
      const _this = this;
      switch(action) {
        case 'reenviar_email':
          _this.model.send_mail = true;
        break;
        case 'gerar_nfse':
          _this.model.send_nfse = true;
        break;
        case 'arquivar_fatura':
          _this.model.archive = true;
        break;
        case 'cencelar_fatura':
          _this.model.cancel = true;
        break;
      }
    }
  },
  mounted(){
    const _id = this.hasIdx();
    if(_id) this.loadForm(_id);
  }
};

</script>
