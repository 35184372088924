<template>
    <main>
      <div class="login" :style="`--bg: url(${baseUrl('/img/bg_login.jpg')})`">
        <div class="login__box">
          <h1 class="login__logo">
            <LogoSvg maxWidth="50px" color1="#fff" color2="#160e44"/>
            <span>Flize Core</span>
          </h1>

          <form class="login__form" @submit.prevent="login" novalidate>
            <div class="login__group">
              <label class="login__label" for="email">E-mail:</label>
              <input class="login__input" v-model="email" required />
            </div>

            <div class="login__group">
              <label class="login__label" for="password">Senha:</label>
              <input class="login__input" v-model="password" type="password" required />
            </div>

            <div class="login__group">
              <button class="login__submit" type="submit">{{ submitBtn }}</button>
            </div>
          </form>
        </div>
      </div>
    </main>
  </template>

  <script>
  import LogoSvg from '@components/_layout/LogoSvg.vue';
  import { baseUrl } from '@helpers/functions';
  import login from '@services/login';
  import storage from 'storage';
  import api from 'api';

  export default {
    components: {
      LogoSvg
    },
    data() {
      return {
        submitBtnDefault: 'Acessar',
        submitBtn: 'Acessar',
        email: '',
        password: '',
      };
    },
    watch: {
      email() {
        this.submitBtn = this.submitBtnDefault;
      },
      password() {
        this.submitBtn = this.submitBtnDefault;
      }
    },
    methods: {
      baseUrl,
      login() {
        const _this = this;

        if(!_this.email || !_this.password) {
          _this.submitBtn = 'Preencha os campos!';
          return;
        }

        _this.submitBtn = 'Logando...';

        login.doLogin(_this.email, _this.password).then((res) => {

          if(res.success === false) {
            _this.submitBtn = res.message;
            return;
          }

          const local = 'flizecore--user';

          _this.submitBtn = 'Logado com sucesso!';
          storage.set(local, res.data);

          api.interceptors.request.use(config => {
            config.headers['X-Access-Token'] = storage.get(local, 'access_token');
            return config;
          }, error => {
            return Promise.reject(error);
          });

          setTimeout(() => {
            _this.$router.push('/dashboard');
          }, 1000);
        });

      },
    },
  };
  </script>
